import { useState } from "react";
import {ProfileBottomNavigation} from './ProfileBottomNavigation'
import { ProfileOptions, ProfileOptionsObjectType } from "../ProfileOptions";
export const ProfileMobileHome = () => {
    const [currentPage, setCurrentPage] = useState<ProfileOptionsObjectType>(ProfileOptions[0])
    
    return (
        <>
        {currentPage.mobilePage}
        <ProfileBottomNavigation currentPage={currentPage} onPageChange={(requestedPage: ProfileOptionsObjectType) => setCurrentPage(requestedPage)}/>
        </>
    )
}