import { Box, Typography } from "@mui/material"

export const AwaitingAppResponse = () => {
    return (
        <Box paddingY="40px">
            <Typography variant="subtitle1">
                <b>
                    Login Successful
                </b>
                <br/>
                Awaiting Application Response
            </Typography>
        </Box>
    )
}