export const ErrorCodes = [
    {
        "code": "auth/too-many-requests",
        "message": "Account temporairly disabled due to too many incorrect attempts. Please try again later or reset your password."
    },
    {
        "code": "auth/wrong-password",
        "message": "Password is incorrect. Please try again"
    },
    {
        "code": "auth/account-exists-with-different-credential",
        "message": "This account is registered with a different service. Please make sure you've logged in with Microsoft for this account before."
    }
]