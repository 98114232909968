import { auth } from "./FirebaseConfig";
import { 
    signInWithPopup,
    OAuthProvider
} from "firebase/auth";



const provider = new OAuthProvider('microsoft.com');
provider.addScope("user.read")
export const SignInMicrosoft = async () => {
    return await signInWithPopup(auth, provider)

}