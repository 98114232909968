import { AppBar, Box, Toolbar } from "@mui/material";
import logo from '../../../../Assets/logo_with_background.png'
import { AccountMenu } from "../../AccountMenu/AccountMenu";
import { BrandColors } from "../../../../Constants";
export const ProfileHeader = () => {
    return (
    <>
    <AppBar>
        <Toolbar sx={{bgcolor: BrandColors.WHITE}}>
        <Box component="img" src={logo} 
        sx={{
            maxWidth: '150px',
        }}/>
        <Box marginLeft="auto">
        <AccountMenu />
        </Box>
        
        </Toolbar>
    </AppBar>
    
    </>)
}