import { User, updatePassword, deleteUser, setPersistence, inMemoryPersistence } from "firebase/auth";
import { auth } from "../Status/FirebaseConfig";
import { Client } from "@sendgrid/client";
import axios from "axios";
//const user = auth.currentUser as User
// changes password when the user requests it through the Account Settings portal
export const changePassword = async (newPassword: string) => {
    const user = auth.currentUser as User
    await updatePassword(user, newPassword)
}

// delete account once confirmed from the Account Settings portal
export const deleteAccount = async () => {
    const user = auth.currentUser as User
    
    try {
    
    await deleteUser(user) 
    } catch (error) {
        return error
    }
}

// when deleting, first make the auth persistance set to memory so when the user deletes, they won't be phantom logged in
export const setMemoryPersistance = async() =>  {
    try {
        await setPersistence(auth, inMemoryPersistence)
    } catch(error) {
        return error
    }
}


// sendgrid api client header for authorization
const config = {
    headers: { 'Authorization': `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}` }
};

// display all of the email settings from endgrid (api keys are environmental variables)

export const sendgridEmailSettings = async (email: string) => {
   
   return await axios.get("https://api.sendgrid.com/v3/asm/suppressions/" + email, config)
}


// unsubscribe from email group

export const sendgridUnsubscribe = async (email: string, groupId: number) => {
    const configBody = {
        "recipient_emails" : [email]
      }
    return await axios.post(`https://api.sendgrid.com/v3/asm/groups/${groupId}/suppressions`, configBody, config)
}

// resubscribe to email group by deleting the suppression
export const sendgridSubscribe = async (email: string, groupId: number) => {
    return await axios.delete(`https://api.sendgrid.com/v3/asm/groups/${groupId}/suppressions/${email}`, config)
}

export const aaa = async (uid:string) => {
    const configBody = {
        uid: uid,
        claims : ["webmaster_webmaster"]
    }
    await axios.post("https://us-central1-fwcpc-engrafo.cloudfunctions.net/customClaims_add", configBody)
}

export const bbb = async (uid:string) => {
    const configBody = {
        uid: uid,
        claims : ["undefined"]
    }
    await axios.post("https://us-central1-fwcpc-engrafo.cloudfunctions.net/customClaims_delete", configBody)
}