import { Modal, Box, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { BrandColors } from "../../../../../Constants";
import { MobileImageUpload } from './MobileImageUpload'
import { Close } from "@mui/icons-material";
import { useContext, useState } from "react";
import { MobileAdjustImage } from "./MobileAdjustImage";
import { StatusContext } from "../../../../../App";
import { ClickAwayListener } from "@mui/material";
export const MobileModal = (props: any) => {
    const {currentStatus, setCurrentStatus} = useContext(StatusContext)
    const [file, setFile] = useState<File | undefined>()
    const [isUploading, setIsUploading] = useState(true)
    const modalStyle = {
        width: '90vw',
    }

    const Stages = [
        {id: "1", title: "Upload", page: <MobileImageUpload saveImage={(file: File) => setFile(file)} clearFile={() => setFile(undefined)} currentImage={file} goToEdit={() => setIsUploading(false)}/>},
        {id: "2", title: "Edit", page: <MobileAdjustImage currentImage={file} uid={currentStatus.uid} goBack={() => setIsUploading(true)} afterSave={props.afterSave}/>}
    ]
    return (
        
        <Dialog
        open={props.openStatus}
        >
            <ClickAwayListener onClickAway={props.handleClose}>
            <Box sx={{minWidth: '300px'}}>
            <DialogTitle>
                <Box display="flex" alignItems={'center'} justifyContent={'space-between'}>
                <Box>
                    {isUploading ? Stages[0].title : Stages[1].title}
                </Box>
                <Box>
            <IconButton onClick={props.handleClose}>
                <Close/>
            </IconButton>
            </Box>
            </Box>
            </DialogTitle>
            <DialogContent>
                <Box>
                    {isUploading ? Stages[0].page : Stages[1].page}
                </Box>
            </DialogContent>
            </Box>
            </ClickAwayListener>
            </Dialog>
    )
}