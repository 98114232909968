import {Box} from '@mui/material'
import { BrandColors } from '../../Constants'
export const AccountDeleted = () => {
    return (
        <>
        <Box color={BrandColors.SUCCESS} paddingBottom="30px">
            <h3>
                Your Account Has Been Deleted Successfully
            </h3>
        </Box>
        <p>
            Thank you for using FWCPC Engrafo. Goodbye
        </p>
        </>
    )
}