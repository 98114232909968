import React from "react"
import { CircularProgress, Box } from "@mui/material"
import { Done } from "@mui/icons-material"
import { BrandColors, BrandType } from "../../Constants"
import { StatusContext } from "../../App"
import { SendLogout } from "../Status/CheckAuthStatus"
import { useQuery } from "react-query"
import Cookies from "js-cookie"
export const Logout = () => {
    const {currentStatus, setCurrentStatus} = React.useContext(StatusContext)
    const fontSize = 'calc(8px + 0.7em)'
    const iconFontSize = 'calc(15px + 1.5em)'
    const [displayGoodbye, setDisplayGoodbye] = React.useState(false)

    const logOutUser = async () => {
        if(currentStatus.logged_in && Cookies.get("__session") !== "") {
        try {
            await SendLogout()
            //casLogout()
           // setDisplayGoodbye(true)
            timeoutToRedirect()
        } catch {
            alert("An error occurred while logging out. Please try again later.")
        }
    } else if(!currentStatus.logged_in) {
        
        if(!Cookies.get("__session") && !currentStatus.logged_in) {
            setDisplayGoodbye(true)
            timeoutToRedirect()
        }
    }
    }

    React.useEffect(() => {
        logOutUser()
    })

    function timeoutToRedirect() {
        setTimeout(() => {
            window.location.href = "https://identity.fwcpchurch.org"
        }, 3000)
    }

    const casLogout = () => {
        window.location.replace("https://fwcpchurch.org/system/identity-auth?logout=true&redirect=https://identity.fwcpchurch.org/logout")
    }
    const LogoutDisplay = () => {

        return (
        <>
        <Box>
            <Box sx={{
                display: 'inline-block',
                textAlign: 'center',
                fontSize: fontSize,
                fontWeight: BrandType._WEIGHT.SEMIBOLD,
                color: BrandColors.DARK_BLUE,
                marginBottom: '5vh'
            }}>
            <Done sx={{fontSize: iconFontSize}}/>
            <Box>Successfully Logged Out
                <br/>To ensure security, please exit the browser.
            </Box>
            </Box>
        </Box>
        </>
        )
    }

    const LoggingOut = () => {
        return (
       <>
       <Box>
            <Box sx={{
                display: 'inline-block',
                textAlign: 'center',
                fontSize: fontSize,
                marginBottom: '5vh'
            }}>
            <CircularProgress />
            <Box marginTop={'2vh'}>Logging You Out</Box>
            </Box>
        </Box>
       </>
        )
    }

    const {data, isLoading} = useQuery({
        queryKey:["loggingout"],
        queryFn: () => logOutUser()
    })

    return (
        <>
        {displayGoodbye ? <LogoutDisplay /> : <LoggingOut />}
        </>

    )
}