import { Box, Button, TextField } from "@mui/material"
import { useContext } from "react";
import { CurrentSizeContext } from "../../App";

export const ForgotPassword = () => {
    const CurrentSize = useContext(CurrentSizeContext)
    let maxWidth = CurrentSize.tablet ? "300px" : "400px";
    return (
        <Box 
        sx={{
            width: maxWidth,
            textAlign:'center',
            margin:'auto'
        }}>
            <TextField fullWidth label="Email Address" />
            <Button fullWidth variant="contained">Submit</Button>
        </Box>
    )
}