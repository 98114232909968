import React from 'react'
import { Typography, Link } from '@mui/material';


function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary"  paddingTop="20px" align="center" {...props}>
        {'© ' +  new Date().getFullYear() + " "}
        <Link color="inherit" href="https://fwcpchurch.org/">
          FWCPC
        </Link>
        <br />
      </Typography>
    );
  }

  export default Copyright