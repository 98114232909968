import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCHi13CG-2Xdw9dweESIu37rfoacTEuD9U",
    authDomain: "identity.fwcpchurch.org",
    projectId: "fwcpc-engrafo",
    storageBucket: "fwcpc-engrafo.appspot.com",
    messagingSenderId: "689203219532",
    appId: "1:689203219532:web:dd023ae6c5d7631539b8e9",
    measurementId: "G-TFFJ26ENDJ"
};

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app);
export const db = getFirestore(app)