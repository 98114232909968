import { Box, Grid, Button } from "@mui/material";
import { BrandColors } from "../../../../../Constants";
import {FileDragAndDrop} from './FileDragAndDrop'
import { Cached, ArrowForward } from "@mui/icons-material";
export const UploadNewImage = (props: any) => {

  return (
    <>
    <Box 
    sx={{
      bgcolor: BrandColors.GRAY.VERY_LIGHT,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: BrandColors.GRAY.REGULAR,
      marginBottom: '20px',
      width: 'inherit',
      padding: '20px',
    }}>
        <FileDragAndDrop clear={props.clearFile} saveImage={(file:File) => props.saveImage(file)}/>
        </Box>
        
        {props.currentImage ?
        <>
        <Box sx={{
          bottom: 0,
          width: 1.0
        }}>
          Filename: {props.currentImage?.name}
        
        <Grid container justifyContent={'space-between'}>
        <Grid item >
        <Button
    startIcon={<Cached />}
    onClick={props.clearFile}>
      Reset
    </Button>
    </Grid>
    <Grid item >
        <Button onClick={props.goToEdit}
        startIcon={<ArrowForward />}
        variant='outlined'>
          Next
        </Button> 
        </Grid>

    </Grid>
    </Box>
        </>: <></>
}

    </>
  );
};


