import { Dialog, DialogTitle, DialogContent, Box, Button, IconButton } from "@mui/material"
import { Close } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { StatusContext } from "../../../../../App"
import { useContext, useState } from "react"
import { UpdatePfpLink } from "../../../GetProfileData"
export const MobileConfirmRemovePicture = (props: any) => {
    const {currentStatus, setCurrentStatus} = useContext(StatusContext)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const RemovePfpLink = async () => {
        try {
            setDeleteLoading(true)
        await UpdatePfpLink(currentStatus.uid, null)
        setDeleteLoading(false)
        props.deleteClose()
        } catch(error) {
            console.log(error)
        }
    }

    return (
        <Dialog
        open={props.deleteOpen}
        >
            <DialogTitle
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Box>
                    Remove Profile Picture
                </Box>
                <Box>
                    <IconButton
                    onClick={props.deleteClose}
                    >
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
            <Box
            sx={{
                width: '60vw',
                minWidth: '250px',
                minHeight: '150px',
                height: '30vh'
            }}>
                Are you sure you want to remove your profile picture?
                <LoadingButton
                variant={'contained'}
                fullWidth
                loading={deleteLoading}
                sx={{
                    marginTop: '50px',
                    marginBottom: '30px'
                }}
                onClick={RemovePfpLink}
                >
                    Confirm
                </LoadingButton>
                <Button
                variant={'outlined'}
                fullWidth
                onClick={props.deleteClose}
                >
                    Cancel
                </Button>
                </Box>
            </DialogContent>
            
        </Dialog>
    )

}