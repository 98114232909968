import { useQuery } from "react-query"
import { useSearchParams, redirect } from "react-router-dom"
import {IdentityGetCustomToken} from '../Status/CheckAuthStatus'
import { CircularProgress, Box } from "@mui/material"
export const ConfirmLoggedIn = () => {

    const {data, isLoading} = useQuery({
        queryKey: ["profileData"],
        queryFn: async() => identityHandleTokenAuth()
    })
    const [searchParams] = useSearchParams()

    const identityHandleTokenAuth = async () => {
        try {
        const token = await IdentityGetCustomToken()
            window.location.href = `https://fwcpchurch.org/system/identity-auth?token=${token.data}&redirect=${searchParams.get('redirect')}`
        } catch {
            window.location.replace(`https://identity.fwcpchurch.org/?redirect=${window.location.href}`)
        }
    }
    return (
    <>
    <Box paddingTop="20px" paddingBottom="60px">
    <CircularProgress />
    <p>Confirming Authentication Status</p>
    </Box>
    </>)
}

