import { auth } from "./FirebaseConfig";
import { signInWithEmailAndPassword , setPersistence, browserLocalPersistence, sendPasswordResetEmail, inMemoryPersistence} from "firebase/auth";

export const LoginUser = async (props: any) => {

    let userCredential = await signInWithEmailAndPassword(auth, props.email, props.password)
    return userCredential
}

export const ForgotPassword = async (email: string) => {
    try {
        return await sendPasswordResetEmail(auth, email)
    } catch (error) {
        return error
    }
}