import {app, db} from './FirebaseConfig'
import {User, getAuth, signInWithCustomToken} from 'firebase/auth'
import { auth } from "./FirebaseConfig";
import axios from 'axios'
import Cookies from 'js-cookie'
import { getDoc, collection, doc, setDoc } from 'firebase/firestore';

export const CurrentLoginStatus = async () => {
    let current = await getAuth(app)

    if(current.currentUser) {
        return {
            displayName: current.currentUser.displayName,
            email: current.currentUser.email,
            profile_picture: current.currentUser.photoURL,
            via: current.currentUser.providerId,
            logged_in: true,
            uid: current.currentUser.uid
        }
    } else {
        return  {
            displayName: "",
            email: "",
            profile_picture: "",
            via: "",
            logged_in: false,
            uid: ""
        }
    }

}

export const AlreadyLogInCheck = async () => {
    let cookie = Cookies.get("CSRFToken")
    let session = Cookies.get("__session")
    const config = {
    headers: { 'Authorization': `Bearer ${cookie}` }
};
    return await axios.post('https://identity.fwcpchurch.org/_auth/checkAuthStatus',{
        session: session
    } , config)
    
}

export const LoginWithSession = async (token: string) => {
    let current = await signInWithCustomToken(auth, token)
    if(current.user) {
        await ConfirmIfNewUserAndIdentityExists(current.user)
        return {
            displayName: current.user.displayName,
            email: current.user.email,
            profile_picture: current.user.photoURL,
            via: current.user.providerId,
            logged_in: true,
            uid: current.user.uid,
            user: current.user
        }
    } else {
        return  {
            displayName: "",
            email: "",
            profile_picture: "",
            via: "",
            logged_in: false,
            uid: ""
        }
    }
}

export const SendLogout = async () => {
    await LogoutFromIdentity()
    let cookie = Cookies.get("CSRFToken")
    let session = Cookies.get("__session")
    const config = {
    headers: { 'Authorization': `Bearer ${cookie}` }
};
    return  await axios.post('https://identity.fwcpchurch.org/_auth/userLogOut',{
        session: session
    } , config)
}

export const LogoutFromIdentity = async() => {
    await auth.signOut()
}


export const GetCurrentUserData = () => {
    let current = auth.currentUser
    current?.reload()
    if(current) {
        return {
            displayName: current.displayName,
            email: current.email,
            profile_picture: current.photoURL,
            via: current.providerId,
            logged_in: true,
            uid: current.uid
        }
    } else {
        return  {
            displayName: "",
            email: "",
            profile_picture: "",
            via: "",
            logged_in: false,
            uid: ""
        }
    }
}

export const IdentityGetCustomToken = async () => {
    const csrfCookie = Cookies.get("CSRFToken")
    const sessionCookie = Cookies.get("__session")

    const config = {
    headers: { 'Authorization': `Bearer ${csrfCookie}` },

    }
    let a = await axios.post('https://identity.fwcpchurch.org/_auth/checkAuthStatus',{
        session: sessionCookie
    }, config)
    return a
    
}
// unused due to Wix / CAS Potential Security Vulnerability
export const CASFirebaseLogout = async () => {
    return await axios.get('https://www.fwcpchurch.org/_functions/logoutCASFirebase')
}

// if new user logs in, place their user data into identity database
export const ConfirmIfNewUserAndIdentityExists = async (user: User, firstName?: string, lastName?: string) => {
    const userDoc = doc(db, `identity/users/account_metadata/${user.uid}`)
    const queryResult = await getDoc(userDoc)
    if(!queryResult.exists()) {
        try {
        return await setDoc(userDoc, {
            first_name: firstName ?? user.displayName,
            last_name: lastName ?? "",
            email: user.email
        })
     } catch(error) {
            return Promise.reject('ERROR IN DATABASE ENTRY CREATION')
        }
        //return Promise.resolve('USER CREATED')
        } else {
        return Promise.resolve('USER EXISTS')
    }
}