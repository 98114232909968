import {useEffect, useState, useCallback, useContext} from 'react';
import {useDropzone} from 'react-dropzone';
import { Box, Button } from '@mui/material';
import { BrandColors } from '../../../../../Constants';
import { CurrentSizeContext } from '../../../../../App';
export function FileDragAndDrop(props: any) {
  const Size = useContext(CurrentSizeContext)
  const onDrop = useCallback((acceptedFiles: File[]) => {
    props.saveImage(acceptedFiles[0])
  }, [])

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': []
    }
  });

/*
  const acceptedFileItems = acceptedFiles.map((file: any) => (
   // console.log(file)
  ));
*/

  

  return (
    <>
      <Box {...getRootProps({ className: 'dropzone' })}
      sx={{
        height: '40vh',
        borderStyle: 'dashed',
        borderWidth: 2,
        textAlign: 'center',
        alignItems:'center',
        justifyContent: 'center',
        display: 'flex'
      }}>
        <input {...getInputProps()} />
        
        <Box>
        <p>Drag & Drop / Click To Upload</p>
        </Box>
      </Box>
      </>
  );
}