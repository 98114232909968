import { Modal, Box, IconButton, Input, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { AdjustCurrentImage } from "./AdjustCurrentImage";
import { UploadNewImage } from "./UploadNewImage";
import { Adjust, Close } from "@mui/icons-material";

export const ProfilePictureModal = (props: any) => {
  const [isUploading, setIsUploading] = useState(true);
  const fileRef = useRef<File | undefined | null>(null)
  const [file, setFile] = useState<HTMLInputElement | null | undefined>()
  
  const style = {
    //position: "absolute",
    //top: "50%",
    //left: "50%",
    //transform: "translate(-50%, -50%)",
    width: "45vw",
    minHeight: "400px",
    minWidth: "700px",
    maxWidth: "1200px",
    maxHeight: "900px",
    height: "60vh",
    //bgcolor: "background.paper",
    //boxShadow: 24,
    //borderRadius: 3,
    //p: 4,
  };

  const handleClose = () => {
    setIsUploading(true)
    props.handleClose()
  }
  
  const goToEdit = () => {
    setIsUploading(false)
  }
  const goToUpload = () => {
    setIsUploading(true)
  }
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <Box sx={{minWidth: '600px', width: '20vw'}}>

          <DialogTitle display="flex" alignItems={'center'} justifyContent={'space-between'}>
          {isUploading ? "Upload Image" : "Edit Image"}
          <Box>
            <IconButton onClick={props.handleClose}>
                <Close />
            </IconButton>
          </Box>
          </DialogTitle>
          </Box>
          <DialogContent>
        <Box textAlign="center">
          {isUploading ? <UploadNewImage clearFile={() => setFile(null)} goToEdit={goToEdit} currentImage={file} saveImage={(image: HTMLInputElement) => setFile(image)} height={style.height}/> : <AdjustCurrentImage uid={props.uid} currentImage={file} close={handleClose} afterSave={props.afterSave} goBack={() => setIsUploading(true)}/>}
        </Box>
        </DialogContent>

    </Dialog>
  );
};
