import {useState, useContext, useEffect} from 'react'
import {Grid, Box, Button, Divider, CircularProgress} from '@mui/material'
import {BrandType} from '../Constants'
import { Profile } from './MemberProfile/Profile/Profile'
import logo from '../Assets/logo_with_background.png'
import  Copyright  from './Copyright'
import { CurrentSizeContext, StatusContext } from '../App'
import { Login } from './Login/Login'
import { CreateAccount } from './CreateAccount/CreateAccount'
import { LoggedInRedirect } from './LogInRedirect/LoggedInRedirect'
import {LoginError} from './LogInRedirect/LoginError'
import { Unauthorized } from './LogInRedirect/Unauthorized'
import { useLocation } from 'react-router-dom'
import {Routes, Route} from 'react-router-dom'
import { Logout } from './Logout/Logout'
import {ConfirmLoggedIn} from './CentralAuthBackwardsCompatibility/ConfirmLoggedIn'
import { AccountDeleted } from './Logout/AccountDeleted'
import { ForgotPassword } from './Forgot Password/ForgotPassword'


export const LoginSignUpToggle = (props: any) => {
    const {currentStatus} = useContext(StatusContext)
    const [headerVisible, setHeaderVisible] = useState(true)
    const CurrentSize = useContext(CurrentSizeContext)
    const [loginVisible, setLoginVisible] = useState(true)
    const location = useLocation()
    const confirmLogin = () => {
        props.checkLogin()
    }

    const EntrySetUpDesktop = () => (
            <Grid container direction="column" sx={{ paddingX: '5vw'}} spacing={2}>
                <Grid item>
                    <Login 
                    goToCreateAccount={() => setLoginVisible(false)}
                    confirmLogin={confirmLogin}
                    />

                </Grid>
                <Grid item>
                    <Box marginY={'3vh'} >
                    <Copyright />
                    </Box>
                </Grid>
                
            </Grid>
    )

    const EntrySetUpMobile = () => (
        <>
            {loginVisible ? 
            <Login 
                goToCreateAccount={() => setLoginVisible(false)}
                confirmLogin={confirmLogin}
            />
            : 
            <CreateAccount 
                goToLogin={() => setLoginVisible(true)}
                confirmLogin={confirmLogin}
            />}
            <Box marginY={'3vh'} >
                <Copyright />
            </Box>
        </>
    )


    const Header = () => (
        <>
            <Box sx={{paddingBottom: '5vh'}}>
            <Box
                    component={'img'}
                    src={logo}
                    sx={{width: '13vw', maxWidth: '250px', minWidth: '150px'}}
            />
            <br />Please login to continue
            </Box>
            <Box paddingX={CurrentSize.tablet ? "6vw" : "25vw"} paddingBottom={'3vh'}>
                <Divider/>
            </Box>
            </>
    )


    useEffect(() => {
        const isAccountCheck = location.pathname.substring(1, location.pathname.substring(1).indexOf("/") + 1)
        if(isAccountCheck == "account" && currentStatus.logged_in) {
            setHeaderVisible(false)
        } else {
            setHeaderVisible(true)
        }
    }, [currentStatus])

  

    
    return (
        <>
        
        
        <Box
         sx={{
            marginTop: 8,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: headerVisible ? "center" : "",
            marginX: headerVisible ? "10vw" : "",
            border: 0,
            borderRadius: 3,
        }}>
        {headerVisible ? <Header /> : <></>}

        <Routes>
        <Route path="/" element={<>{currentStatus.logged_in ? <LoggedInRedirect /> : <Login goToCreateAccount={() => setLoginVisible(false)} confirmLogin={confirmLogin}/>}</>}/>
          <Route path="logout" element={<Logout />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/compatibility/authentication-status" element={<ConfirmLoggedIn/>}/>
          <Route path="goodbye" element={<AccountDeleted />} />
          <Route path="/account/profile" element={currentStatus.logged_in ? <Profile /> :  <Login goToCreateAccount={() => setLoginVisible(false)} confirmLogin={confirmLogin}/>} />
        </Routes>

        <Copyright />
        </Box>
        </>
    )
}