
import {List, ListItem, ListItemText, ListItemButton, ListItemIcon, createTheme, ThemeProvider} from '@mui/material'
import { Person, Lock, Notifications } from '@mui/icons-material'
import { BrandColors } from '../../../../Constants';
import {ProfileOptions} from '../ProfileOptions'
export const ProfileSideBar = (props: any) => {
    const theme = createTheme({
        palette: {
          primary: {
            main: BrandColors.WHITE
          },

        },
        components: {
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: BrandColors.GRAY.VERY_LIGHT,
                            color: BrandColors.GRAY.LIGHT,
                        },
                        '&.Mui-selected' : {
                            backgroundColor: BrandColors.WHITE,
                            color: 'black'
                        },
                        '&.Mui-focusVisible' : {
                            backgroundColor: BrandColors.ENGRAFO.MIDDLE,
                            color: BrandColors.ENGRAFO_DARK
                        },
                    },
                    
                }
            },


        }
      });

    return (
        <>
        <ThemeProvider theme={theme} >
        <List>
            {ProfileOptions.map((x, index) => (
                <ListItem key={index.toString()} disablePadding sx={{paddingY: '10px'}}>
                    <ListItemButton
                
                    onClick={() => props.onPageChange(x)}
                    selected={props.currentPage.title == x.title ? true : false}
                    >
                            <ListItemIcon sx={{minWidth: '40px'}}>
                                {x.icon}
                            </ListItemIcon>
                        <ListItemText primary={x.title} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
        </ThemeProvider>
        </>
    )
}