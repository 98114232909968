import { useState, useContext, useEffect, useCallback, HTMLInputTypeAttribute } from "react";
import {
    Box,
    TextField,
    Avatar,
    IconButton,
    Button,
    Grid,
    Skeleton,
    Typography
} from "@mui/material";
import { stringAvatar } from "../../AccountMenu/AccountMenu";
import {
    CameraAlt
} from '@mui/icons-material'
import { StatusContext } from "../../../../App";
import { BrandColors, ProfileDataType } from "../../../../Constants";
import {GetCurrentUserData} from '../../../Status/CheckAuthStatus'
import { useQuery, useQueryClient } from "react-query";
import {GetProfileData, UpdatePfpLink} from '../../GetProfileData'
import { UpdateUserMetadata } from "../../ProfileAccountChanges";
import { ProfilePictureModal } from "./Modal/ModalHome";
import { ProfileMessageContext } from "./ProfileHome";
export const ProfileAccount = (props: any) => {
    const {currentStatus, setCurrentStatus} = useContext(StatusContext)
    const {notification, setNotification} = useContext(ProfileMessageContext)
    
    const [profileData, setProfileData] = useState<ProfileDataType>()
    const [modalOpen, setModalOpen] = useState(false)
    const [saveChangeShow, setSaveChangeShow] = useState(false)
    const queryClient = useQueryClient()
    const {data, isLoading} = useQuery({
        queryKey: ["profileData"],
        queryFn: () => async () =>{
            await getProfileDataFrontEnd()
        }
    })

    const getProfileDataFrontEnd = async() => {
        let profileDta = await GetProfileData(currentStatus.uid)
        setProfileData(profileDta as ProfileDataType)
    }

    const handleAccountMetadataChangeBtnClick = async () => {
        await UpdateUserMetadata(profileData as ProfileDataType, currentStatus.uid)
        setNotification({...notification, message: "Changes Saved Successfully", show: true})
    }

    const handleAccountMetadataInput = (e: any, type: string) => {
        setProfileData({... profileData as ProfileDataType, [type]: e.target.value})
        setSaveChangeShow(true)

    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const handleModalClose = async () => {
        setModalOpen(false)
    }

    const handleAfterPictureSave = async () => {
        let newUserData = await GetCurrentUserData()
        setCurrentStatus(newUserData)
    }

    useEffect(() => {
        handleModalClose()
    }, [currentStatus.profile_picture])

    const RemovePfpLink = async () => {
        try {
        await UpdatePfpLink(currentStatus.uid, null)
        } catch(error) {
            console.log(error)
        }
    }
    return (
        <>
        
       <Box display={'flex'} alignItems='center' justifyContent={'center'}>
            <Box>
                <Box>
                    
            <Avatar
                          src={currentStatus?.profile_picture?.toString()}
                          {...stringAvatar(currentStatus?.displayName, "200px", 'calc(30px + 2.0em)')}
                        >
                          {currentStatus.displayName
                            ?.toString()
                            .substring(0, 1)
                            .toUpperCase()}
                            
                        </Avatar>
                        
                        </Box>
            <IconButton
            onClick={handleModalOpen}
            sx={{
                position: 'absolute',
                transform: 'translate(120px, -80px)'
            }}>
            
            <CameraAlt 
            sx={{
                minWidth: '50px',
                minHeight: '50px', 
                padding: '10px',
                borderRadius: 20,
                boxShadow: 1,
                bgcolor: BrandColors.WHITE
            }}/>
            
            </IconButton>
            <Box textAlign={'center'} paddingTop={'20px'} marginX="20px">
            <Button fullWidth onClick={RemovePfpLink} variant="outlined" disabled={currentStatus.profile_picture ? false : true}>Remove Picture</Button>
            </Box>
            </Box>
            {profileData ? 
            <Grid container direction="column" spacing={3} width="450px" paddingLeft='60px'>
                <Grid item>
                    <TextField fullWidth label="First Name" InputLabelProps={{ shrink: true }}  onChange={(e: any) => handleAccountMetadataInput(e, 'first_name')} value={profileData.first_name}/>
                </Grid>
                <Grid item>
                    <TextField fullWidth label="Last Name" InputLabelProps={{ shrink: true }}  onChange={(e: any) => handleAccountMetadataInput(e, 'last_name')} value={profileData.last_name}/>
                </Grid>
                <Grid item>
                    <TextField fullWidth label="Email Address" InputLabelProps={{ shrink: true }}  onChange={(e: any) => handleAccountMetadataInput(e, 'email')}  value={profileData.email} helperText="Used for notifications & login."/>
                </Grid>
                <Grid item textAlign={'right'} sx={{visibility: saveChangeShow ? '' : 'hidden'}}>
                    <Box>
                    <Button variant="contained" onClick={handleAccountMetadataChangeBtnClick}>
                        Save
                    </Button>
                    <Button variant="outlined" onClick={getProfileDataFrontEnd}>
                        Cancel
                    </Button>
                    </Box>
                </Grid>
            </Grid>
            : <Box paddingLeft='60px'><Skeleton width="350px" height={'50px'}/> <Skeleton width="350px" height={'50px'} /> <Skeleton width="350px" height={'50px'} /></Box>}
             
            </Box>
           <Box textAlign={'center'} marginTop="50px" marginX={'150px'}>
           <p>CAS/MAS Member ID: <b>{profileData?.old_member_id}</b></p>
           </Box>
          {<ProfilePictureModal open={modalOpen} handleClose={handleModalClose} uid={currentStatus.uid} afterSave={handleAfterPictureSave}/>}
        
        </>
    )
}