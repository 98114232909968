import {Person, Lock, Notifications, Work} from '@mui/icons-material'
import {ProfileAccount} from './ProfileDesktop/ProfileAccount'
import { ProfileNotifications } from './ProfileDesktop/ProfileNotifications'
import { ProfileSecurity } from './ProfileDesktop/ProfileSecurity'
import { ProfileMobileAccount } from './ProfileMobile/ProfileMobileAccount'
import { ProfileRoles } from './ProfileDesktop/ProfileRoles'

export const ProfileOptions = [
    {
        order: 1,
        title: "Personal",
        icon: <Person />,
        page: <ProfileAccount />,
        mobilePage: <ProfileMobileAccount />
    },
    {
        order: 2,
        title: "Security",
        icon: <Lock />,
        page: <ProfileSecurity />,
        mobilePage: <ProfileSecurity />
    },
    {
        order: 3,
        title: "Notifications",
        icon: <Notifications />,
        page: <ProfileNotifications />,
        mobilePage: <ProfileNotifications />
    },
    {
        order: 4,
        title: "Roles",
        icon: <Work />,
        page: <ProfileRoles />,
        mobilePage: <></>
    }
]

export type ProfileOptionsArrayType = ProfileOptionsObjectType[]

export type ProfileOptionsObjectType = {
    order: number;
    title: string;
    icon: any;
    page: any;
    mobilePage: any
}