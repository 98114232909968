export const BrandColors = {
    "DARK_BLUE": "#073366",
    'LIGHT_BLUE': "#0081d4",
    'ENGRAFO_MAIN': '#6FA9C6',
    'ENGRAFO_DARK': '#2C5D71',
    BROWN: "#363535",
    ENGRAFO: {
        LIGHT: "#659AB4",
        MAIN: "6FA9C6",
        MIDDLE: "#4E839C",
        DARK: "#2C5D71",
        DARKER: '#3F6C7E'
    },
    'GRAY': {
        VERY_LIGHT: '#f2f2f2',
        "LIGHT": '#808080',
        'REGULAR': '#454442'
    },
    'SUCCESS': '#2d870f',
    'FAILURE': '#940f06',
    WHITE: '#FFFFFF'
}
export const ErrorFontSizeHeader = 'calc(10px + 1.5em)'
export const ErrorFontSizeText='calc(5px + 0.8em)'
export const BrandType = {
    MONTSERRAT: "'Montserrat', sans-serif",
    WORKSANS: "'Work Sans', sans-serif",
    _WEIGHT: {
        THIN: 100,
        LIGHT: 200,
        REGULAR: 400,
        SEMIBOLD: 600,
        BOLD: 700
    }
}

export const style = {
    maxWidth: '330px',
    width: '22vw',
    minWidth: '250px',
    textAlign: 'left',
    fontWeight: BrandType._WEIGHT.SEMIBOLD
}

export const rowStyle = {
    maxWidth: '330px',
    width: '22vw',
    minWidth: '250px',
    textAlign: 'center',
    fontWeight: BrandType._WEIGHT.REGULAR,
    justifyContent: 'space-between',
    display: 'inline-flex'
}

export const gStyle = {
    maxWidth: '330px',
    width: '22vw',
    minWidth: '250px',
    textAlign: 'center',
    fontWeight: BrandType._WEIGHT.SEMIBOLD
}

export type CurrentUserType = {
    displayName: string | null;
    email: string | null | undefined ;
    profile_picture: string | null | undefined; 
    via: string | null | undefined; 
    logged_in: boolean;
    uid: string
}
export const CurrentUserDefault = {
    displayName: "",
    email: "",
    profile_picture: "",
    via: "",
    logged_in: false,
    uid: "default"
}

export type ProfileDataType = {
    first_name: string | null | undefined;
    last_name: string | null | undefined;
    email: string | null | undefined;
    old_member_id: string | undefined;
    old_wix_id: string | undefined
}