import { Modal, Box, Slider, Typography, Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useContext, useState, useRef, useEffect } from "react";
import { StatusContext } from "../../../../../App";
import { Save, Done, Upload } from "@mui/icons-material";
import lgo from '../../../../Assets/logo_with_background.png'
import AvatarEditor from "react-avatar-editor";

import { useQuery } from "react-query";
import {UploadImageBlob, GetNewImageUrl, UpdatePfpLink} from '../../../GetProfileData'
export const AdjustCurrentImage = (props: any) => {
  const [loadingFinished, setLoadingFinished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [imgScale, setImgScale] = useState(1.0);
  const cropRef = useRef<AvatarEditor | null>(null)

  const handleSave = async () => {
    setLoading(true)
    if(cropRef.current) {
        const dataUrl = cropRef.current.getImage().toDataURL()
        const result = await fetch(dataUrl)
        const blob = await result.blob()
        let extension = String(blob.type.split('/').pop())
        let uploadData = await UploadImageBlob(blob, props.uid, extension)
        if(uploadData?.metadata) {
          let imageUrlNew = await GetNewImageUrl(uploadData.metadata.fullPath)
          if(imageUrlNew) {
            await UpdatePfpLink(props.uid, imageUrlNew)
            props.afterSave()
            setLoadingFinished(true)
          }
          setLoading(false)
          props.afterSave()
        }
    }
  }

  const shortenImagePathName = (pathName: string) => {
    if(pathName.length > 25) {
      return `${pathName.substring(0,10)}...${pathName.substring(pathName.length - 6)}`
    } else {
      return pathName
    }
  }
  
    return (
      <>
        <Box

        >
          <Grid container direction={'row'} justifyContent="space-between" alignItems={'center'}>
            <Grid item>
            <AvatarEditor
          ref={cropRef}
            image={props.currentImage}
            width={250}
            height={250}
            border={10}
            borderRadius={999}
            color={[0, 0, 0, 0.3]} // RGBA
            scale={imgScale}
            rotate={0}
            
          />
            </Grid>
            <Grid item>
              <Box>
                File Name: {shortenImagePathName(props.currentImage.name)}
              </Box>
            <Typography id="image-scale-slider" gutterBottom>
              Scale
            </Typography>
            <Slider
              defaultValue={1}
              aria-label="Default"
              max={5}
              
              min={0.5}
              step={0.05}
              valueLabelDisplay="auto"
              value={imgScale}
              sx={{
                width: '300px'
              }}
              onChange={(event, value) => setImgScale(value as number)}
            />

            </Grid>
          </Grid>


          <Grid container spacing={2} direction="row">
            <Grid item>
          <Button
            variant="outlined"
            onClick={props.goBack}
            startIcon={<Upload/>}>
              
              Use Another Image
            </Button>
          </Grid>
          <Grid item>
          <LoadingButton
            variant="contained"
            aria-label="Save Profile Image"
            startIcon={<Save />}
            disabled={loadingFinished}
            loading={loading}
            onClick={handleSave}
          >
            Save Picture
          </LoadingButton>
          </Grid>
          <Grid item>
          <Button
          variant="outlined"
          disabled={!loadingFinished}
          startIcon={<Done/>}
          onClick={props.afterSave}
          >
            Finish
          </Button>
          </Grid>
          </Grid>
         </Box> 
       
      </>
    );
};
