import {auth} from './FirebaseConfig'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'

export const CreateNewUser = async(email:string, password:string) => {
    try {
        return await createUserWithEmailAndPassword(auth, email, password)
    } catch(error) {
        return error
    }
}

export const UpdateDisplayName = (name:string) => {
    let promise = new Promise(async (resolve, reject) => {
    try {
        if(auth.currentUser) {
            let user = await updateProfile(auth.currentUser, {displayName: name})
            resolve(user)
        } else {
            throw("USER NOT LOGGED IN")
        }
    } catch (e) {
        reject(e)
    } 
    })
    return promise
}