import {Box, Button, Grid, TextField, IconButton, Alert, AlertTitle} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useContext, useState, useEffect } from 'react'
import { LoadingButton } from '@mui/lab'
import { changePassword, deleteAccount, setMemoryPersistance } from '../../ProfileSecurityChanges'
import { BrandColors } from '../../../../Constants'
import { ProfileMessageContext } from './ProfileHome'
type PasswordInputType = {
    password: string | undefined
    confirmPassword: string | undefined,
    passwordsMatch: boolean
    showPasswordMismatch: boolean
    passwordChangeLoading: boolean
}
type ConfirmDeleteAccountType = {
    showConfirmation: boolean
    confirmationInput: string
}
export const ProfileSecurity = (props: any) => {
    const {notification, setNotification} = useContext(ProfileMessageContext)
    const [passwordInput, setPasswordInput] = useState<PasswordInputType>()
    const [hidePassword, setHidePassword] = useState(true)
    const [hideConfirmPassword, setHideConfirmPassword] = useState(true)
    const [confirmDeleteAccount, setConfirmDeleteAccount] = useState<ConfirmDeleteAccountType>({showConfirmation: false, confirmationInput: ""})
    const [accountMetadata, setAccountMetadata] = useState("")

    const handlePasswordInput = (input: string, type: string) => {
        setPasswordInput({ ...passwordInput, [type]: input } as PasswordInputType)
    }

    const cancelDeleteInputConfirm= () => {
        setConfirmDeleteAccount({showConfirmation: false, confirmationInput: ""})
    }

    const cancelPasswordChange = () => {
        setPasswordInput(undefined)
    }

    const checkIfPasswordAndConfirmMatch = () => {
        if(passwordInput?.password === passwordInput?.confirmPassword) {
            setPasswordInput({ ...passwordInput, passwordsMatch: true} as PasswordInputType)
            handleChangePassword()

        } else {
            handlePasswordMismatch()
        }
    }

    const handleChangePassword = async () => {
        if(passwordInput?.password && passwordInput.password.length > 6) {
            setPasswordInput({ ...passwordInput, passwordChangeLoading: true} as PasswordInputType)
            try {
                await changePassword(passwordInput?.password)
                setNotification({...notification, message: "Password Changed Successfully", show: true})
            } catch (error) {
                setNotification({...notification, message: "An Error Occurred. Password not Changed", show: true, severity: 'error'})
            } finally {
                setPasswordInput({ ...passwordInput, passwordChangeLoading: false} as PasswordInputType)
            }
        }
    }
    const handlePasswordMismatch = () => {
        setPasswordInput({ ...passwordInput, showPasswordMismatch: true} as PasswordInputType)
        setTimeout(() => {
            setPasswordInput({ ...passwordInput, showPasswordMismatch: false} as PasswordInputType)
        }, 3000)
    }

    useEffect(() => {
        if(confirmDeleteAccount.confirmationInput.toLowerCase() == "confirm" && confirmDeleteAccount.showConfirmation) {
            handleDeleteAccount()
        }
    }, [confirmDeleteAccount.confirmationInput])

    const handleDeleteAccount = async () => {
        try {
            setNotification({...notification, message: "Your account is being prepared for deletion.", show: true, severity: "info"})
            await setMemoryPersistance()
            setNotification({...notification, message: "Final processing. One moment please.", show: true, severity: "info"})
            await deleteAccount()
            window.location.replace(window.location.origin + "/goodbye")
        } catch (error) {
            console.log(error)
            setNotification({...notification, message: "An error occurred during the account deletion process.", show: true, severity: 'error'})
        }
    }
    return (
    <Box sx={{
        paddingX: '20px',
        justifyItems:'space-between',
        display: 'flex',
        flexDirection: 'column'
    }}>
    <Box marginBottom="min(6vh, 20px)" >
        <h4>
            Manage Your Password
        </h4>
        <Grid container justifyContent={'center'} direction="column" paddingX={'13vw'} spacing={2}>
            <Grid item textAlign={'center'}>
            <TextField label="Change Password" type="text" fullWidth
            
            value={passwordInput?.password}
            helperText="Enter your new password"
            onInput={(e: any) => handlePasswordInput(e.target.value, "password")}
        InputProps={{
            endAdornment: (
              <IconButton onClick={() => setHidePassword(!hidePassword)}>
                {hidePassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
            </Grid>
            <Grid item visibility={passwordInput?.password ?  'visible' : "hidden"}>
                <TextField label="Confirm Password" fullWidth
                value={passwordInput?.confirmPassword}
                onInput={(e: any) => handlePasswordInput(e.target.value, "confirmPassword")}
                 InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setHideConfirmPassword(!hidePassword)}>
                        {hideConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}/>
            </Grid>
            <Grid item visibility={passwordInput?.password ?  'visible' : "hidden"} alignItems="center">
                
                <Box sx={{float: 'right'}} display="inline-flex" alignItems={'center'}>
                    <Box paddingRight={'15px'} color={BrandColors.FAILURE} display={passwordInput?.showPasswordMismatch ? "" : "none"}>
                    <i >
                    Passwords do not match.
                </i>
                </Box>
                <Button variant="outlined"  sx={{marginRight: '10px'}} 
                onClick={cancelPasswordChange}>
            Cancel
        </Button>
            <LoadingButton variant="contained"  sx={{marginLeft: '10px'}}
            onClick={checkIfPasswordAndConfirmMatch}
            loading={passwordInput?.passwordChangeLoading}
            >
            Confirm
        </LoadingButton>
        </Box>
            </Grid>
          
        </Grid>

        </Box>
        <Box>
            <h4>
                Delete My Account
            </h4>
            <Grid container justifyContent="center">
                {confirmDeleteAccount.showConfirmation ?  <Grid item md={6}>
                    <TextField fullWidth helperText='Write "confirm" to delete your account' 
                    inputProps={{ style: { textAlign: 'center' }}}
                    value={confirmDeleteAccount.confirmationInput}
                    onChange={(e: any) => setConfirmDeleteAccount({...confirmDeleteAccount, confirmationInput: e.target.value})}
                     />
                    <Box sx={{float: 'right'}}>
                <Button variant="outlined" onClick={cancelDeleteInputConfirm}>
            Cancel
        </Button>
        </Box>
            </Grid> :  <Grid item md={4}>
                    <Button fullWidth variant="outlined" onClick={(e: any) => setConfirmDeleteAccount({...confirmDeleteAccount as ConfirmDeleteAccountType, showConfirmation: true})}>
                        Delete My Account
                    </Button>
            </Grid>}
            </Grid>
        </Box>
    </Box>
    )
}